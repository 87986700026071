<!-- 页脚 -->

<template>
  <div>
    <!-- 第一部分 -->
    <div class="part1">
      <div class="part1-wrap">
        <div class="title">{{ $t('footer.part1_title1') }}</div>
        <div>{{ $t('footer.part1_text1') }}</div>

        <div class="part1-2-wrap">
          <div class="left">
            <div class="withContactUs">{{ $t('footer.withContactUs') }}</div>
            <div>{{ $t('footer.part1_text2') }}</div>
            <div v-for="(item, index) in goodsRemarkList" :key="index">
              <div v-if="item.type===2" class="form">
                <input type="text" v-model="formList[index].value[0]" :placeholder="formList[index].prompt ? formList[index].prompt : $t('footer.phonePlaceholder')" />
							</div>
              <div class="btn-wrap">
                <button @click="sumbitForm">{{ $t('footer.submitBtn') }}</button>
              </div>
            </div>
          </div>

          <div class="right">
            <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/5c13b644-78af-4b4e-86d4-09ee9855a204.png"
              alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- 第二部分 -->
    <div class="part2">
      <div class="part2-wrap">
        <div class="linkList-wrap" v-if="$currentLanguage != 'en'">
          <div v-for="(linkListItem, linkListIndex) in linkList" :key="linkListIndex" class="linkListItem">
            <div class="linkTitle">{{ linkListItem.title }}</div>
            <div v-for="(linkItem, linkIdex) in linkListItem.linkArr" :key="linkIdex" class="linkLabel">
              <a :href="linkItem.url">{{ linkItem.label }}</a>
            </div>
          </div>
        </div>

        <!-- 竖线 -->
        <div class="line" v-if="$currentLanguage != 'en'"></div>

        <!-- 联系 -->
        <div class="contactUs">
          <!-- 左边 -->
          <div class="contactUsLeft" :style="[$currentLanguage == 'en'?contactUsLeftstyle:'']">
            <div class="contactUsTitle">{{ $t('footer.contactUsTitle') }}</div>
            <div class="phone" :style="{'fontSize':$currentLanguage == 'en'?'20px':''}">{{$currentLanguage != 'en'?'135-3385-9130':'trendcloud888@gmail.com'}}</div>
            <div class="consultation">{{ $t('footer.consultation') }}</div>
          </div>
           <!-- 竖线 -->
          <div class="line" v-if="$currentLanguage == 'en'"></div>
          <!-- 右边 -->
          <div class="contactUsRight" :style="[$currentLanguage == 'en'?contactUsRightstyle:'']">
            <div class="lxkf">
              <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/material/5e92f070-0e09-4b4f-a5c0-8d677afcba2c.png"
                alt="">
              <div>{{ $t('footer.contactCustomerService') }}</div>
            </div>
            <div class="gzh">
              <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/b490c965-fa04-4e95-926c-09cf47dc75ba.png"
                alt="">
              <div>{{ $t('footer.officialAccount') }}</div>
            </div>
             <div class="lxkf" v-if="$currentLanguage == 'en'">
              <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/43444b47-b875-4650-9315-e5d73c72ab0e.png"
                alt="">
              <div>Tik Tok</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 第三部分 -->
    <div class="part3">
      <div>{{ $t('footer.companyName') }}</div>
      <div class="icp-warp" @click="toICP()">{{ $t('footer.icp') }}</div>
    </div>
  </div>
</template>

<script>
import { getCustomByFormId, postFormDataById } from "@/api/api.js"
import { isMobile } from "@/utils/validate.js"

export default {
  name: "customFooter",

  data() {
    return {
      contactUsRightstyle:{
         'width':'auto',
         'marginLeft':'210px',
         'flex-wrap': 'nowrap',
      },
      contactUsLeftstyle:{
        //  'width':'200px',
         'marginRight':'150px',
      },
      customFormId: process.env.VUE_APP_CUSTOM_FORM_ID, //表单组id
      goodsRemarkList: [], //表单接口返回的表单控件数据
      formList: [], //表单

      linkList: [
        {
          title: this.$t('footer.linkTitle1'), //"热门链接",
          linkArr: [
            {
              label: this.$t('footer.linkTitle1_label1'), //"趋势连锁",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle1_label2'), //"AI肤质检测",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle1_label3'), //"AI体质检测",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle1_label4'), //"美业分销",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle1_label5'), //"客户案例",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle1_label6'), //"免费试用",
              url: "/"
            },
          ]
        },
        {
          title: this.$t('footer.linkTitle2'), //"解决方案",
          linkArr: [
            {
              label: this.$t('footer.linkTitle2_label1'), //"医美机构",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle2_label2'), //"化妆品",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle2_label3'), //"产后护理",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle2_label4'), //"中医理疗",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle2_label5'), //"舞蹈健身",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle2_label6'), //"舞蹈健身",
              url: "/"
            },
          ]
        },
        {
          title: this.$t('footer.linkTitle3'), //"探索趋势美业",
          linkArr: [
            {
              label: this.$t('footer.linkTitle3_label1'), //"关于我们",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle3_label2'), //"新闻中心",
              url: "/"
            },
          ]
        },
        {
          title: this.$t('footer.linkTitle4'), //"趋势系列产品",
          linkArr: [
            {
              label: this.$t('footer.linkTitle4_label1'), //"趋势云",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle4_label2'), //"趋势美业",
              url: "/"
            },
            {
              label: this.$t('footer.linkTitle4_label3'), //"趋势智能",
              url: "/"
            },
          ]
        }
      ], //链接
    }
  },

  mounted() {
    this.getRemarkGroupById(); //根据id获取表单控件数据
  },

  methods: {
    // 根据id获取表单控件数据
    getRemarkGroupById() {
      getCustomByFormId({
        remarkGroupId: this.customFormId,
      }).then(res => {
        // console.log("根据id获取表单控件数据", res);
        this.goodsRemarkList = res.data && res.data.goodsRemarkList;
        if(this.goodsRemarkList && this.goodsRemarkList.length>0){
          this.initFormData(); //整理获取到的表单数据
        }
      })
    },


    // 整理获取到的表单数据
    initFormData() {
      //初始化提交表单,前提goodsRemarkList有数据
      let arr = this.goodsRemarkList.map((item, index) => {
        let obj = {}
        let { id: remarkId, name, type, sort, value, prompt } = item
        if (item.type === 4 || item.type === 5) {
          let value2 = value.map((item, index) => {
            return { key: item.key, value: [] }
          })
          obj = { remarkId, name, type, sort, value: value2, prompt }
        } else {
          obj = { remarkId, name, type, sort, value: [], prompt }
        }
        return obj
      })
      this.formList = JSON.parse(JSON.stringify(arr))
      // console.log("this.formList>>", this.formList);
    },


    // 提交表单
    sumbitForm() {
      if (!this.formList[0].value[0]) {
        this.$message.error(this.$t('footer.phnoe_msg'));
      } else if (!isMobile(this.formList[0].value[0])) {
        this.$message.error(this.$t('footer.phnoe_msg2'));
      } else {
        // 接口
        postFormDataById({
          userId: "",
					groupId: this.customFormId,
					remarkItems: this.formList,
					relationId: ''
        }).then(res => {
          // console.log("提交表单结果", res);
          if(res.code == 0 || res.code == 200){
            this.$message.success(res.msg ? res.msg : this.$t('footer.subSuccess'));
          }else{
            this.$message.error(res.msg ? res.msg : this.$t('footer.sunFail'));
          }
        })
      }
    },

    // 跳转备案官网
    toICP() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }
  }
}
</script>

<style lang="less" scoped>
.part1 {
  background: #27292B;
  // height: 885.8px;
  color: #FFFFFF;
  padding: 0 363px;
}

.part1-wrap {
  padding-top: 102px;
  font-size: 16px;

  .title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 21px;
  }
}

.part1-2-wrap {
  margin-top: 74px;
  display: flex;
  justify-content: space-between;

  .left {
    width: 440px;

    .withContactUs {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 22px;
    }

    .form {
      margin-top: 35px;

      input {
        width: 100%;
        height: 56px;
        padding: 0 10px;
        border-radius: 5px;
      }
    }

    .btn-wrap {
      margin-top: 21px;

      button {
        background: #04CB94;
        width: 100%;
        height: 56px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .right {
    width: 502px;

    img {
      width: 100%;
    }
  }
}

.part2 {
  background: #27292B;
  // height: 885.8px;
  color: #FFFFFF;
  padding: 0 363px;
  padding-top: 84px;
}

.part2-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 29px;

  // 链接区域
  .linkList-wrap {
    display: flex;
    justify-content: space-between;
    width: 40%;

    .linkListItem {
      padding: 0 5px;
    }

    .linkTitle {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 28px;
    }

    .linkLabel {
      margin-bottom: 10px;
    }

    a {
      color: #ffffff71;
    }

    a:hover {
      color: #ffffff;
    }
  }

  // 竖线
  .line {
    height: 211px;
    width: 1px;
    background-color: #EEEEEE;
  }

  // 联系
  .contactUs {
    display: flex;

    .contactUsLeft {
      .contactUsTitle {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 28px;
      }

      .phone {
        font-size: 26px;
        font-weight: 900;
        color: #52C018;
        font-style: italic; //斜体
        margin-bottom: 26px;
      }

      .consultation {
        font-size: 14px;
        font-weight: 500;
      }
    }

    .contactUsRight {
      display: flex;
      flex-wrap: wrap;
      width: 369px;
      .lxkf {
        width: 108px;
        height: 108px;
        margin-left: 54px;
        text-align: center;

        img {
          width: 100%;
          margin-bottom: 17px;
        }
      }

      .gzh {
        width: 108px;
        height: 108px;
        margin-left: 54px;
        text-align: center;

        img {
          width: 100%;
          margin-bottom: 17px;
        }
      }
    }
  }
}

.part3 {
  background: #212224;
  font-size: 12px;
  color: #FFFFFF;
  padding: 24px 0 21px;
  display: flex;
  justify-content: center;

  .icp-warp {
    margin-left: 15px;
    cursor: pointer;
  }
}</style>