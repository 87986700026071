import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Home from "@/views/home/index.vue"
import MiniProgram from "@/views/MiniProgram/index.vue"
import MeiyeChain from "@/views/MeiyeChain/index.vue"
import MeiyeDistribution from "@/views/MeiyeDistribution/index.vue"
import AboutUs from "@/views/AboutUs/index.vue"
import CustomerStories from "@/views/CustomerStories/index.vue"
import MeiyeIot from "@/views/MeiyeIot/index.vue"







Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      content: {
        title: '美业数字化解决方案领先互联网saas平台 - 趋势云',
        keywords: '美业小程序开发,美容院系统,医美软件系统,美业管理系统,美业连锁系统',
        description: '趋势云,19年提供美业小程序开发,美容院系统,医美软件系统,美业管理系统,美业连锁系统整体解决方案,为美业品牌连锁提供数字门店解决方案，搭建全链路私域生态,帮助美业门店实现客户私域沉淀，自动营销私域增长'
      },
    }
  },
  {
    path: '/MiniProgram',
    name: 'MiniProgram',
    component: MiniProgram,
    meta: {
      content: {
        title: '美业小程序开发_美容院小程序_医美小程序_美业门店小程序 - 趋势云',
        keywords: '美业小程序开发,美业小程序制作,美容院小程序,医美小程序,美业门店小程序',
        description: '趋势云提供美业小程序开发,美业小程序制作,美容院小程序,医美行业小程序,美业门店小程序整体解决方案,为美业品牌连锁提供数字门店解决方案，搭建全链路私域生态,帮助美业门店实现客户私域沉淀，自动营销私域增长'
      },
    }
  },
  {
    path: '/MeiyeChain',
    name: 'MeiyeChain',
    component: MeiyeChain,
    meta: {
      content: {
        title: '美业连锁小程序_美容院连锁系统_医美连锁系统_美业连锁管理系统_美业连锁门店小程序 - 趋势云',
        keywords: '美业连锁小程序,美容院连锁系统,医美连锁系统,美业连锁管理系统,美业连锁门店小程序',
        description: '趋势云提供美业连锁小程序,美容院连锁系统,医美连锁系统,美业连锁管理,美业门店小程序系统整体解决方案,为美业品牌连锁提供数字门店解决方案，搭建全链路私域生态,帮助美业门店实现客户私域沉淀，自动营销私域增长'
      },
    }
  },
  {
    path: '/MeiyeIot',
    name: 'MeiyeIot',
    component: MeiyeIot,
    content: {
      title: '',
      keywords: '',
      description: ''
    },
 },
 {
  path: '/MeiyeDistribution',
  name: 'MeiyeDistribution',
  component: MeiyeDistribution,
  content: {
    title: '美业分销小程序_美容院分销系统_医美分销系统_美业分销系统 - 趋势云',
    keywords: '美业分销小程序,美容院分销系统,医美分销系统,美业分销系统',
    description: '趋势云提供美业分销小程序,美容院分销系统,医美分销系统,美业分销系统整体解决方案,为美业品牌连锁提供数字门店解决方案，搭建全链路私域生态,帮助美业门店实现客户私域沉淀，自动营销私域增长'
  },
},
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs,
    content: {
      title: '关于我们_美业数字化解决方案领先互联网saas平台 - 趋势云',
      keywords: '美业小程序开发,美容院系统,医美软件系统,美业管理系统,美业连锁系统',
      description: '趋势云提供美业小程序开发,美容院系统,医美软件系统,美业管理系统,美业连锁系统整体解决方案,为美业品牌连锁提供数字门店解决方案，搭建全链路私域生态,帮助美业门店实现客户私域沉淀，自动营销私域增长'
    },
  },
  {
    path: '/CustomerStories',
    name: 'CustomerStories',
    component: CustomerStories,
    content: {
      title: '客户案例_美业数字化解决方案领先互联网saas平台 - 趋势云',
      keywords: '美业小程序开发,美容院系统,医美软件系统,美业管理系统,美业连锁系统',
      description: '趋势云提供美业小程序开发,美容院系统,医美软件系统,美业管理系统,美业连锁系统整体解决方案,为美业品牌连锁提供数字门店解决方案，搭建全链路私域生态。帮助美业门店实现客户私域沉淀，自动营销私域增长'
    },
  },
  {
    path: '/articlList',
    name: '文字列表',
    component: () => import ( '@/views/articl/articlList/index.vue'),
  }
]

const router = new VueRouter({
  routes
})

router.afterEach((to,from,next)=>{
  window,scrollTo(0,0); //路由跳转新页面后回到顶部
})

export default router
